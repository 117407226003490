
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import useTenantSettingsStore from '@/stores/tenantSettings';
import Snackbar from '@/components/commonComponents/Snackbar.vue';
import Tour from '@/components/commonComponents/Tour.vue';
import DialogHandler from '@/components/DialogHandler.vue';
import { GenericTranslations } from '@/plugins/translations/src';

// Layouts
// Lazy loading imports
const BlankLayout = () => import(
  /* webpackChunkName: "blank-layout" */
  /* webpackPrefetch: false */
  '@/layouts/BlankLayout.vue'
);
const FullLayout = () => import(
  /* webpackChunkName: "full-layout" */
  /* webpackPrefetch: false */
  '@/layouts/FullLayout.vue'
);
const Error = () => import(
  /* webpackChunkName: "error" */
  /* webpackPrefetch: false */
  '@/layouts/Error.vue'
);

interface Colors {
  'primary_color'?: string|null
  'primary_background_color'?: string|null
  'primary_panel_background_color'?: string|null,
  'secondary_panel_background_color'?: string|null,
  'primary_text_color'?: string|null,
  'secondary_text_color'?: string|null,
  'primary_link_color'?: string|null,
  'primary_list_link_color'?: string|null,
  'primary_button_color'?: string|null,
  'primary_button_text_color'?: string|null,
}

interface Logos {
  'logo_main'?: string|null,
  'logo_login'?: string|null,
}

interface Theme extends Logos, Colors { }

interface TenantSettings {
  authMethods: Array<string>,
  theme: Theme,
  locale: string,
  datetimeLocale: string,
  compactMode?: boolean | null,
}

interface Settings {
  tenantSettings: TenantSettings;
  genericTranslation: GenericTranslations;
}

@Component({
  name: 'App',
  components: {
    DialogHandler,
    FullLayout,
    BlankLayout,
    Snackbar,
    Error,
    Tour,
  },
})
export default class App extends Vue {
  private tenantSettingsStore = useTenantSettingsStore();

  protected isReady = false;

  protected showError = false;

  created(): void {
    this.fetchTenantSettings()
      .then(({ tenantSettings, genericTranslation }) => {
        this.storeTenantSettings(tenantSettings);
        this.$setTranslations(genericTranslation);
      })
      .then(() => {
        this.isReady = true;
      })
      .catch((error) => {
        this.showError = true;
        throw error;
      });
  }

  static setCompactMode(): void {
    document.body.classList.add('compact-mode');
  }

  private async storeTenantSettings(tenantSettings: TenantSettings): Promise<void> {
    const {
      theme, authMethods, locale, compactMode, datetimeLocale,
    } = tenantSettings;
    this.tenantSettingsStore.authMethods = authMethods;
    this.$vuetify.lang.current = locale;
    this.tenantSettingsStore.datetimeLocale = `${locale}-${datetimeLocale ?? locale}`;
    this.tenantSettingsStore.vuetifySettings = this.$vuetify;

    this.setLogos(theme);

    this.setColor(theme.primary_color, 'primary_color');
    this.setColor(theme.primary_background_color, 'primary_background_color');
    this.setColor(theme.primary_panel_background_color, 'primary_panel_background_color');
    this.setColor(theme.secondary_panel_background_color, 'secondary_panel_background_color');
    this.setColor(theme.primary_text_color, 'primary_text_color');
    this.setColor(theme.secondary_text_color, 'secondary_text_color');
    this.setColor(theme.primary_link_color, 'primary_link_color');
    this.setColor(theme.primary_list_link_color, 'primary_list_link_color');
    this.setColor(theme.primary_button_color, 'primary_button_color');
    this.setColor(theme.primary_color, 'primary');
    this.setColor(theme.primary_link_color, 'anchor');
    this.setColor(theme.primary_button_text_color, 'primary_button_text_color');

    if (compactMode) {
      App.setCompactMode();
    }
  }

  private setLogos(settings: Theme): void {
    if (typeof settings.logo_main === 'string') {
      this.tenantSettingsStore.logoMain = settings.logo_main;
    }
    if (typeof settings.logo_login === 'string') {
      this.tenantSettingsStore.logoLogin = settings.logo_login;
    }
  }

  private setColor(color: string|null|undefined, item: string): void {
    const includedColorsDarkTheme = [
      'primary_color', 'primary_link_color', 'primary_list_link_color', 'primary_button_color',
    ];
    if (typeof color === 'string') {
      this.$vuetify.theme.themes.light[item] = color;
      if (includedColorsDarkTheme.includes(item)) {
        this.$vuetify.theme.themes.dark[item] = color;
      }
    }
  }

  private fetchTenantSettings(): Promise<Settings> {
    return this.$api.queryExecute('tenant-settings')
      .then((response: { data: { settings: Settings }}) => response.data.settings);
  }
}
