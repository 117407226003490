import Vue from 'vue';

export interface GenericTranslations {
  [key: string]: string|GenericTranslations,
}

const Translations = {
  translations: {} as { [key: string]: string },
  getTranslation: (key: string, fallback?: string): string => {
    const translation = Translations.translations[key];

    if (translation !== undefined) {
      return translation;
    }

    if (fallback !== undefined) {
      return fallback;
    }
    return key;
  },
  setTranslations: (translations: GenericTranslations): void => {
    Translations.translations = Translations.flatten(translations);
  },
  flatten: (obj: GenericTranslations|string, path = ''): Record<string, string> => {
    let flat: Record<string, string> = {};
    if (typeof obj === 'string') {
      return { [path]: obj };
    }

    Object.keys(obj).forEach((property: string) => {
      const newPath = path !== '' ? `${path}.${property}` : property;
      flat = { ...flat, ...(Translations.flatten(obj[property], newPath)) };
    });

    return flat;
  },
  install(): void {
    Vue.prototype.$t = (key: string, fallback?: string) => this.getTranslation(key, fallback);
    Vue.prototype.$setTranslations = (translations: GenericTranslations) => this.setTranslations(translations);
  },
};

export default Translations;
