
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
} from 'vue-property-decorator';
import VJsf from '@koumoul/vjsf/lib/VJsf';
import {
  Schema,
  Model,
  Options,
  Value,
} from '@/types/vjsf';
import ActionButton from '@/components/Button/Button.vue';
import { isEqual } from 'lodash';
import useScreenHandlerStore from '@/stores/screenHandler';

import { Metadata } from '@/types/ListTypes';
import useTenantSettingsStore from '@/stores/tenantSettings';

// Lazy loading imports
const DateTimeField = () => import(
  /* webpackChunkName: "date-time-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/DateTime.vue'
);
const DateField = () => import(
  /* webpackChunkName: "date-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Date.vue'
);
const TimeField = () => import(
  /* webpackChunkName: "time-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Time.vue'
);
const CurrencyField = () => import(
  /* webpackChunkName: "currency-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Currency.vue'
);
const FilesField = () => import(
  /* webpackChunkName: "files-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Files.vue'
);
const MarkdownField = () => import(
  /* webpackChunkName: "markdown-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Markdown.vue'
);
const PreviewField = () => import(
  /* webpackChunkName: "preview-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Preview.vue'
);
const RadioGroup = () => import(
  /* webpackChunkName: "radio-group-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/RadioGroup.vue'
);
const AddOption = () => import(
  /* webpackChunkName: "add-option-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/AddOption.vue'
);
const ColorPicker = () => import(
  /* webpackChunkName: "color-picker-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/ColorPicker.vue'
);

const CodeField = () => import(
  /* webpackChunkName: "code-field" */
  /* webpackPrefetch: false */
  '@/components/formElements/fields/Code.vue'
);

type Target = '_blank' | '_parent' | '_self' | '_top';

interface Icon {
  icon: string,
  href?: string,
  clickable?: boolean,
  target?: Target,
  isDialog?: boolean,
}

interface Icons {
  [key: string]: Icon,
}

@Component({
  name: 'VJsfForm',
  components: {
    ActionButton,
    AddOption,
    RadioGroup,
    VJsf,
    DateField,
    DateTimeField,
    TimeField,
    CurrencyField,
    FilesField,
    MarkdownField,
    PreviewField,
    ColorPicker,
    CodeField,
  },
})
export default class VJsfForm extends Vue {
  @PropSync('model')
  private readonly formModel!: Model;

  @Prop({ required: true })
  private readonly schema!: Schema;

  @Prop()
  protected readonly navigationSlug?: string;

  @Prop()
  protected readonly dossierTypeSlug?: string;

  @Prop()
  protected readonly dossierId?: number;

  @Prop({ required: true })
  private readonly options!: Options;

  @Prop()
  private readonly metadata?: Metadata;

  @Prop({ default: true })
  private readonly showIcons!: boolean;

  @Prop({ default: true })
  private readonly canCheckForDirty!: boolean;

  @Prop()
  private readonly initialModel!: Model;

  private screenHandler = useScreenHandlerStore();

  private tenantSettingStore = useTenantSettingsStore();

  private icons: Icons = {};

  @Watch('formModel', { deep: true })
  private changeModel() {
    if (this.canCheckForDirty) {
      this.screenHandler.check(this.uid, this.isDirty());
    }
  }

  private isDirty(): boolean {
    const cleanValue = (value: unknown) => (value !== null && value !== '');
    const cleanInitialModel = Object.fromEntries(Object.entries(this.initialModel ?? {}).filter(([_, v]) => cleanValue(v)));
    const cleanFormModel = Object.fromEntries(Object.entries(this.formModel ?? {}).filter(([_, v]) => cleanValue(v)));

    return !isEqual(cleanInitialModel, cleanFormModel);
  }

  addNewOption(id: number, propertyName: string): void {
    this.formModel[propertyName] = id;
    this.$emit('rerender');
  }

  private handleSubmit(propertyName: string): void {
    const ignoredTypes = ['textarea', 'markdown', 'autocomplete'];

    if (propertyName !== '' && !this.isOfType(propertyName, ignoredTypes)) {
      this.$emit('submit');
    }
  }

  private isOfType(propertyName: string, types: string[] | string): boolean {
    let format = this.schema.properties[propertyName]?.format ?? '';

    // Temp solution for file upload
    if (this.schema.properties[propertyName]?.contentMediaType === '*') {
      format = 'files';
    }

    if (['radio', 'textarea', 'autocomplete', 'file_preview', 'code'].includes(this.schema.properties[propertyName]['x-display'] ?? '')) {
      format = this.schema.properties[propertyName]['x-display'] ?? '';
    }

    return typeof types === 'string'
      ? [types].includes(format)
      : types.includes(format);
  }

  private overwriteFields(propertyName: string, types: string[]): string {
    return this.isOfType(propertyName, types) ? propertyName : '';
  }

  private static getIcon(icon: string, href: string, value: Value, target: Target = '_self', isDialog = false): Icon {
    return {
      icon,
      href,
      clickable: !!value,
      target,
      isDialog,
    };
  }

  @Watch('schema')
  private setElementIcons(): void {
    const propertyNames = Object.keys(this.schema.properties);

    propertyNames.forEach((propertyName: string) => {
      const value = this.formModel[propertyName];
      const fieldType = this.schema.properties[propertyName]['x-display'];
      const dossierLink = this.metadata?.dossier_link as Metadata;

      // Add dummy icon to overwritten fields. Icons are added by the fields themselves
      if (['date-time', 'time', 'date'].includes(this.schema.properties[propertyName].format ?? '')) {
        this.icons[propertyName] = { icon: 'mdi-calendar' };
      }

      switch (fieldType) {
        case 'tel': this.icons[propertyName] = VJsfForm.getIcon('mdi-phone', `tel:${value}`, value);
          break;
        case 'email': this.icons[propertyName] = VJsfForm.getIcon('mdi-email', `mailto:${value}`, value, '_blank');
          break;
        case 'url': this.icons[propertyName] = VJsfForm.getIcon('mdi-web',
          !`${value}`.startsWith('/') && !`${value}`.startsWith('http') ? `//${value}` : `${value}`,
          value,
          '_blank');
          break;
        case 'autocomplete':
        case 'select':
          if (typeof dossierLink?.[propertyName] === 'string') {
            const link = dossierLink[propertyName];
            this.icons[propertyName] = VJsfForm.getIcon(
              'mdi-open-in-new',
              `${link}`,
              link,
              '_self',
              true,
            );
          }
          break;
        default:
          break;
      }
    });
  }

  private openDialog(link: string) {
    let currentHash = this.$route.hash;
    if (this.$route.hash.length === 0) {
      currentHash = window.location.hash.replace('#', '');
    }

    let newHash = currentHash;
    if (newHash.length > 0) {
      newHash = `${currentHash}|${link}`;
    } else {
      newHash = link;
    }

    const routerData = {
      hash: decodeURI(newHash),
    };

    // Most used option for links which will be resolved by the vue router
    this.$router.push(routerData);
  }
}
