import Vue from 'vue';
import Message from '@/components/mixins/Message.vue';

import ApiResolver from './api-resolver';

const API = {
  install(): void {
    Vue.component(Message.name, Message);

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = ApiResolver.getInstance();
  },
};

export default API;
