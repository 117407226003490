import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

/**
 * Initialize Sentry. so errors get centralized logging
 */
export default (): void => {
  const dsn = process.env.VUE_APP_SENTRY_DSN;

  if (typeof dsn === 'undefined' || dsn === '') {
    console.debug('No sentry DSN provided, not initializing Sentry');
    return;
  }

  Sentry.init({
    dsn,
    integrations: [new Integrations.Vue({
      Vue,
      attachProps: true,
    })],
  });
};
