import {
  Options, Step, Steps,
} from '@/plugins/v-tour/types';

interface Settings {
  'label_next': string,
  'label_previous': string,
  'label_skip': string,
  'label_stop': string,
}

type Placement = 'left'|'right'|'top'|'bottom';

interface IncomingStep {
  content: string,
  header: string,
  offset: number,
  placement: Placement,
  target: string,
}

type OutgoingStep = Step;

interface IncomingDTO {
  id: number,
  label: string,
  show: boolean,
  settings: Settings
  steps: Array<IncomingStep>
}

interface OutgoingDTO {
  options: Options
  steps: Steps
  show?: boolean,
  id?: number,
}

const ResponseStep = (
  {
    content,
    header,
    offset,
    placement,
    target,
  }: IncomingStep,
): OutgoingStep => ({
  target,
  header: {
    title: header,
  },
  content,
  offset,
  params: {
    placement: placement.toLowerCase() as Placement,
  },
});

const ResponseOptions = (
  {
    label_next: labelNext,
    label_previous: labelPrevious,
    label_skip: labelSkip,
    label_stop: labelStop,
  }: Settings,
): Options => ({
  labels: {
    buttonPrevious: labelPrevious,
    buttonSkip: labelSkip,
    buttonNext: labelNext,
    buttonStop: labelStop,
  },
});

const ResponseToTour = (
  {
    id,
    show,
    settings,
    steps,
  }: IncomingDTO,
): OutgoingDTO => ({
  options: ResponseOptions(settings),
  steps: steps.map(ResponseStep),
  show,
  id,
});

export { ResponseToTour, IncomingDTO, OutgoingDTO };
