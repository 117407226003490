
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import useTenantSettingsStore from '@/stores/tenantSettings';

@Component({
  name: 'Snackbar',
})
export default class Snackbar extends Vue {
  private readonly snackbar = useTenantSettingsStore().snackbar;
}
