
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import ButtonHandler from '@/components/Button/Button';
import {
  ButtonType,
  ClickAction,
  ClickActions,
} from '@/types/ButtonTypes';

@Component({
  name: 'Button',
})
export default class Button extends Vue {
  @Prop({ required: true })
  public readonly title!: string;

  @Prop({ default: () => [] })
  public readonly clickActions!: ClickActions|ClickAction;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: '' })
  public readonly additionClasses!: string;

  @Prop()
  public readonly confirmMessage?: string;

  @Prop({ default: 'default' })
  public readonly type!: ButtonType;

  private buttonHandler: ButtonHandler;

  constructor() {
    super();
    this.buttonHandler = new ButtonHandler(this.type, this.confirmMessage, this.clickActions);
  }

  private async handleClick(): Promise<void> {
    if (this.buttonHandler.needsConfirm() && !await this.showConfirmableAlert()) {
      return;
    }

    this.buttonHandler.executeActions();
    this.$emit('executeAction');
  }

  private async showConfirmableAlert(): Promise<boolean> {
    return this.$swal.fire(
      this.buttonHandler.getSweetAlertOptions(),
    ).then(({ isConfirmed }) => isConfirmed);
  }
}

