import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2';
import { Vue } from 'vue-property-decorator';

const useScreenHandlerStore = defineStore('screenHandler', {
  state: () => ({
    dirtyElements: [] as Array<string>,
  }),
  actions: {
    isScreenDirty(): boolean {
      return this.dirtyElements.length >= 1;
    },
    reset(): void {
      this.dirtyElements = [];
    },
    addDirtyElement(elementId: string): void {
      if (this.dirtyElements.indexOf(elementId) === -1) {
        this.dirtyElements.push(elementId);
      }
    },
    removeDirtyElement(elementId: string) : void {
      const index = this.dirtyElements.indexOf(elementId);
      if (index > -1) {
        this.dirtyElements.splice(index, 2);
      }
    },
    check(elementId: string, isDirty: boolean): void {
      if (isDirty) {
        this.addDirtyElement(elementId);
      } else {
        this.removeDirtyElement(elementId);
      }
    },
    async leaveScreen(): Promise<boolean> {
      if (this.isScreenDirty()) {
        const { isConfirmed } = await Swal.fire({
          title: Vue.prototype.$t('generic.unsaved-data.title'),
          text: Vue.prototype.$t('generic.unsaved-data.description'),
          icon: 'warning',
          confirmButtonText: Vue.prototype.$t('generic.confirm.confirm'),
          confirmButtonColor: 'var(--v-primary_button_color-base)',
          showCancelButton: true,
          cancelButtonText: Vue.prototype.$t('generic.confirm.cancel'),
          cancelButtonColor: 'var(--v-primary_button_color-base)',
        });

        if (isConfirmed) {
          this.reset();
        }

        return isConfirmed;
      }
      return true;
    },
  },
});

export default useScreenHandlerStore;
