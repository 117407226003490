import { defineStore } from 'pinia';
import { Route } from 'vue-router';
import { urlHashToArray } from '@/helpers/converters/url';

interface Blocks {
  [location: string]: Array<{title: string, type: string}>
}

interface DialogContext {
  [context: string]: { blocks: Blocks }
}

interface State {
  currentRoute?: Route,
  dialogsContext: Array<DialogContext>,
}

function populateTemplate(string: string|undefined, placeholders: Record<string, string>|undefined): string|undefined {
  if (string === undefined || placeholders === undefined) {
    return '';
  }

  return string.replace(/({\w+?})/g, (_, name) => {
    const newString = placeholders[name.replace(/{|}/g, '')];
    return `${newString?.charAt(0).toUpperCase()}${newString?.slice(1)}`;
  });
}

const useDocumentTitleStore = defineStore('documentTitle', {
  state: (): State => ({
    dialogsContext: [],
  }),
  actions: {
    setCurrentRoute(route: Route): void {
      this.currentRoute = route;
      this.setTitle();
    },
    setTitle(): void {
      process.nextTick(() => {
        document.title = `Flux Flow | ${this.getDocumentTitle()}`;
      });
    },
    addHashInfo(hashInfo: DialogContext): void {
      this.dialogsContext.push(hashInfo);
      this.setTitle();
    },
    getDocumentTitle(): string {
      let title = this.getBaseTitle();
      if (this.getDialogTitle().length > 0) {
        title = `${title} / ${this.getDialogTitle()}`;
      }

      return title;
    },
    getRouteTitleTemplate(): string|undefined {
      return this.currentRoute?.meta?.title ?? undefined;
    },
    getRouteTemplateParams(): Record<string, string>|undefined {
      return this.currentRoute?.params;
    },
    getBaseTitle(): string {
      return populateTemplate(this.getRouteTitleTemplate(), this.getRouteTemplateParams()) ?? '';
    },
    getDialogTitleArray(): {[context: string]: string|undefined} {
      if (this.dialogsContext.length <= 0) {
        return {};
      }
      let titles: {[context: string]: string|undefined} = {};

      this.dialogsContext.forEach((dialogContext) => {
        let headerTitle: string|undefined;
        let blockTitle: string|undefined;
        const key = Object.keys(dialogContext).at(0) as string;

        Object.keys(dialogContext[key].blocks as Blocks)
          .every((location) => dialogContext[key].blocks[location]
            .every((block) => {
              if (block.type.includes('header')) {
                headerTitle = block.title;
                return false;
              }
              if (blockTitle === undefined) {
                blockTitle = block.title;
              }
              return true;
            }));
        if (headerTitle ?? blockTitle) {
          titles = {
            ...titles,
            [key]: headerTitle ?? blockTitle,
          };
        }
      });

      return titles;
    },
    getDialogTitle(): string {
      const titles: Array<string> = [];

      urlHashToArray(this.currentRoute?.hash).forEach((context) => {
        const title = this.getDialogTitleArray()?.[`#${context}`];
        if (title) {
          titles.push(title);
        }
      });

      return titles.join(' / ');
    },
  },
});

export default useDocumentTitleStore;
