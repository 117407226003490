import { defineStore } from 'pinia';
import {
  Options,
  Steps,
  Tour,
  Tours,
} from '@/plugins/v-tour/types';

interface State {
  tourOperator?: Tour,
  options: Options
  steps: Steps
  name: string,
  show?: boolean,
  id?: number,
  fullPath?: string,
}

const useTourStore = defineStore('tour', {
  state: (): State => ({
    options: {
      highlight: false,
      labels: {
        buttonPrevious: '',
        buttonSkip: '',
        buttonNext: '',
        buttonStop: '',
      },
      enabledButtons: {
        buttonSkip: true,
        buttonPrevious: true,
        buttonNext: true,
        buttonStop: true,
      },
      startTimeout: 0,
      stopOnTargetNotFound: false,
      useKeyboardNavigation: true,
      enabledNavigationKeys: {
        escape: true,
        arrowRight: true,
        arrowLeft: true,
      },
      debug: false,
    },
    steps: [],
    name: 'tour',
    show: false,
  }),
  actions: {
    setTourOperator(tours: Tours) {
      this.tourOperator = tours[this.name];
    },
    start(step = 0) {
      if (this.tourOperator !== undefined && this.show) {
        this.tourOperator.start(`${step}`);
      }
    },
    manualStart(step = 0) {
      if (this.tourOperator !== undefined) {
        this.tourOperator.start(`${step}`);
      }
    },
    stop() {
      if (this.tourOperator !== undefined) {
        this.tourOperator.stop();
      }
    },
    skip() {
      if (this.tourOperator !== undefined) {
        this.tourOperator.skip();
      }
    },
    previousStep() {
      if (this.tourOperator !== undefined) {
        this.tourOperator.previousStep();
      }
    },
    nextStep() {
      if (this.tourOperator !== undefined) {
        this.tourOperator.nextStep();
      }
    },
    finish() {
      if (this.tourOperator !== undefined) {
        this.tourOperator.finish();
      }
    },
  },
});

export default useTourStore;
