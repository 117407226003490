import UriTemplate from 'uritemplate';
import LinkObject from '@/types/LinkTypes';

export function replaceUrl(url: string, variables: { [x: string]: string; }): string {
  return UriTemplate.parse(url).expand(variables);
}

export function linkObjectToString(linkObject: LinkObject): string {
  const { url, data } = linkObject;

  return replaceUrl(url, data);
}

export function urlHashToArray(url: string|undefined): Array<string> {
  if (url === '' || url === undefined) {
    return [];
  }
  return url.replace('#', '').split('|');
}

export function hashArrayToUrl(urlArray: Array<string>): string {
  if (urlArray.length >= 1) {
    return `#${urlArray.join('|')}`;
  }
  return '';
}

interface BaseHashObject {
  hash: string,
  matched: string,
  name: 'detail'|'task'|'module'|'dialogView'
  params: Record<string, string|number>
}

interface DetailHashObject extends BaseHashObject {
  name: 'detail',
  params: {
    dossierTypeSlug: string,
    navigationSlug: string,
    dossierId: number,
  }
}

interface TaskHashObject extends BaseHashObject {
  name: 'task',
  params: {
    taskId: number,
  }
}

interface ModuleHashObject extends BaseHashObject {
  name: 'module',
  params: {
    moduleName: string,
    moduleItemId: number,
  }
}

interface DialogViewHashObject extends BaseHashObject {
  name: 'dialogView',
  params: {
    viewSlug: string,
    moduleItemId: number,
  }
}

export type HashObject = DetailHashObject | TaskHashObject | ModuleHashObject | DialogViewHashObject;

function getDetailHashObject(url: string): HashObject {
  // eslint-disable-next-line no-template-curly-in-string
  const matched = '#dossier-${dossierTypeSlug}/${navigationSlug}/${dossierId}';
  const [dossierTypeSlug, navigationSlug, dossierId] = url
    .replace(/^#?dossier-/, '')
    .split('/');

  return {
    hash: `#${url}`,
    name: 'detail',
    matched,
    params: {
      dossierTypeSlug,
      navigationSlug,
      dossierId: parseInt(dossierId, 10),
    },
  };
}

function getModuleHashObject(url: string): HashObject {
  // eslint-disable-next-line no-template-curly-in-string
  const matched = '#view-${moduleName}/${moduleItemId}';
  const [moduleName, moduleItemId] = url
    .replace(/^#?view-/, '')
    .split('/');

  return {
    hash: `#${url}`,
    name: 'module',
    matched,
    params: {
      moduleName,
      moduleItemId: parseInt(moduleItemId, 10),
    },
  };
}

function getDialogViewHashObject(url: string): HashObject {
  // eslint-disable-next-line no-template-curly-in-string
  const matched = '#view-${viewSlug}/${moduleItemId}';
  const [viewSlug, moduleItemId] = url
    .replace(/^#?view-/, '')
    .split('/');

  return {
    hash: `#${url}`,
    name: 'dialogView',
    matched,
    params: {
      viewSlug,
      moduleItemId: parseInt(moduleItemId, 10),
    },
  };
}

export function urlHashToObjectArray(url: string): Array<HashObject> {
  const hashArray = urlHashToArray(url);
  const hashObjectArray: Array<HashObject> = [];

  hashArray.forEach((hashString) => {
    if (hashString.match(/^#?dossier-/)) {
      hashObjectArray.push(getDetailHashObject(hashString));
    } else if (hashString.match(/^#?view-[a-zA-Z]+/)) {
      hashObjectArray.push(getModuleHashObject(hashString));
    } else if (hashString.match(/^#?view-[\d]+/)) {
      hashObjectArray.push(getDialogViewHashObject(hashString));
    }
  });

  return hashObjectArray;
}
