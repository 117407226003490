import Vue from 'vue';
import VueApollo from 'vue-apollo';
import apolloClient from './apollo-client';

Vue.use(VueApollo);

/**
 * Create an Apollo provider to be used with Vue
 * See https://apollo.vuejs.org/guide/apollo/#apollo-options for usage
 */
export default new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading',
  },
});
