import UriMapEntry from '@/plugins/apollo/api-uri-map-entry';
import UriMapRegexEntry from '@/plugins/apollo/api-uri-map-regex-entry';

export class ApiUriResolver {
  readonly mapping: (UriMapEntry | UriMapRegexEntry)[] = [
    new UriMapEntry('http://fluxflow.com/', 'http://api.fluxflow.com/'),
    new UriMapEntry('https://fluxflow.com/', 'https://api.fluxflow.com/'),
    new UriMapRegexEntry(/(https?:\/\/)([a-z0-9-]+)\.(fluxflow\.com\/)/, '$1$2.api.$3'),

    new UriMapEntry('http://fluxflow.dev/', 'http://api.fluxflow.dev/'),
    new UriMapEntry('https://fluxflow.dev/', 'https://api.fluxflow.dev/'),
    new UriMapRegexEntry(/(https?:\/\/)([a-z0-9-]+)\.(fluxflow\.dev\/)/, '$1$2.api.$3'),

    new UriMapEntry('http://flux-flow-frontend.localhost/', 'http://flux-flow-app.localhost/'),
    new UriMapEntry('https://flux-flow-frontend.localhost/', 'https://flux-flow-app.localhost/'),
    new UriMapRegexEntry(/(https?:\/\/[a-z0-9-]+\.flux-flow)(-frontend\.)(localhost\/)/, '$1-app.$3'),

    // insert .api. at third domain level, see https://regex101.com/r/7CYHF4/1
    new UriMapRegexEntry(/(?<protocol>https?:\/\/)(?<tenant>[a-z0-9-]+)\.(?<domain>[^.]*)\.(?<tld>[a-zA-Z]*\/)/, '$1$2.api.$3.$4'),
  ];

  private location: Location = window.location;

  constructor(location: Location = window.location) {
    this.location = location;
  }

  /**
   * Give the API uri based on the current location
   * First try exact mapping, then try regexp mapping and finally just return the href
   */
  public resolveUri(path = ''): string {
    const appUri = `${this.location.protocol}//${this.location.hostname}/`;

    const uriMapValue = (this.mapping
      .filter((map) => map instanceof UriMapEntry) as UriMapEntry[])
      .find((map) => map.key === appUri)?.value;
    const uriMapRegexValue = (this.mapping
      .filter((map) => map instanceof UriMapRegexEntry) as UriMapRegexEntry[])
      .find((map) => appUri.match(map.regex))
      ?.getUri(appUri);

    return (uriMapValue || uriMapRegexValue || appUri).concat(path);
  }
}

export default new ApiUriResolver();
