
import {
  Watch,
  Vue,
  Component,
} from 'vue-property-decorator';
import useAuthStore from '@/stores/auth';
import { HashObject, urlHashToObjectArray } from '@/helpers/converters/url';
import Dialog from '@/components/commonComponents/Dialog.vue';

type DialogType = 'detail'|'dialog';

interface DialogProperties {
  graphqlOperations: string,
  viewSlug: string|number,
  type: DialogType,
  dossierTypeSlug: string,
  navigationSlug: string,
  dossierId: number,
  hash?: string,
  index?: number,
  context?: string,
}

@Component({
  name: 'DialogHandler',
  components: {
    Dialog,
  },
})
export default class DialogHandler extends Vue {
  private authStore = useAuthStore();

  protected dialogArray: Array<DialogProperties> = [];

  private urlObjectArray: Array<HashObject> = [];

  @Watch('$route.hash')
  private async setDialogArray(): Promise<void> {
    const hashObjectArray = urlHashToObjectArray(this.$route.hash);
    const newDialogArray: Array<DialogProperties> = [];
    let { dossierTypeSlug, navigationSlug } = this.$route.params;
    let dossierId = parseInt(this.$route.params.dossierId, 10);

    if (await this.isDialogAllowed()) {
      hashObjectArray.forEach((urlHashObject) => {
        const dialogProperties = DialogHandler.getDialogProperties(
          urlHashObject,
          dossierId,
          dossierTypeSlug,
          navigationSlug,
        );
        dossierId = dialogProperties.dossierId;
        dossierTypeSlug = dialogProperties.dossierTypeSlug;
        navigationSlug = dialogProperties.navigationSlug;

        newDialogArray.push(dialogProperties);
      });
    }

    this.dialogArray = newDialogArray;
  }

  private async isDialogAllowed(): Promise<boolean> {
    const isAuthenticated = await this.authStore.isAuthenticated();
    const isOnEntryPage = typeof this.$route.name === 'string' && ['login', 'register', 'resetLogin'].includes(this.$route.name);

    return isAuthenticated && !isOnEntryPage;
  }

  private static getDialogProperties(
    urlHashObject: HashObject,
    dossierId: number,
    dossierTypeSlug: string,
    navigationSlug: string,
  ): DialogProperties {
    return {
      type: DialogHandler.getDialogType(urlHashObject.hash),
      dossierTypeSlug: DialogHandler.getDossierTypeSlug(urlHashObject, dossierTypeSlug),
      navigationSlug: DialogHandler.getNavigationSlug(urlHashObject, navigationSlug),
      dossierId: DialogHandler.getDossierId(urlHashObject, dossierId),
      viewSlug: DialogHandler.getViewSlug(urlHashObject),
      context: urlHashObject.hash,
      graphqlOperations: 'queries/dossier-type-block-layout-by-view-slug',
    };
  }

  private static getDossierTypeSlug(urlHashObject: HashObject, currentDossierTypeSlug: string): string {
    if (urlHashObject.name === 'detail') {
      return urlHashObject.params.dossierTypeSlug;
    }

    return currentDossierTypeSlug;
  }

  private static getNavigationSlug(urlHashObject: HashObject, currentNavigationSlug: string): string {
    if (urlHashObject.name === 'detail') {
      return urlHashObject.params.navigationSlug;
    }

    return currentNavigationSlug;
  }

  private static getDossierId(urlHashObject: HashObject, currentDossierId: number): number {
    if (urlHashObject.name === 'detail') {
      return urlHashObject.params.dossierId;
    }

    return currentDossierId;
  }

  private static getViewSlug(urlHashObject: HashObject): string {
    if (urlHashObject.name === 'dialogView') {
      return urlHashObject.params.viewSlug;
    }

    if (urlHashObject.name === 'module') {
      return urlHashObject.params.moduleName;
    }

    return '';
  }

  private static getDialogSlug(dialogString: string): string {
    return dialogString.split('/')[0];
  }

  private static getDialogType(dialogString: string): DialogType {
    const slug = DialogHandler.getDialogSlug(dialogString);
    return slug.includes('dossier-') ? 'detail' : 'dialog';
  }
}
