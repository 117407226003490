
import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Route } from 'vue-router';
import useTourStore from '@/stores/tour';

@Component({
  name: 'Tour',
})
export default class Tour extends Vue {
  private tourStore = useTourStore();

  private currentRoutePath: string|undefined;

  mounted(): void {
    this.tourStore.setTourOperator(this.$tours);

    if (this.tourStore.$state.show) {
      this.tourStore.start();
    }
  }

  // Close tour if user navigates to a different page
  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newRoute: Route): void {
    if (this.currentRoutePath === undefined) {
      this.currentRoutePath = newRoute.fullPath;
    } else if (this.currentRoutePath !== newRoute.fullPath) {
      this.tourStore.stop();
    }
  }

  private finishTour(): void {
    this.tourStore.finish();

    const variables = {
      id: this.tourStore.$state.id,
    };

    import('@/graphql/mutations/mark-tour-done-by-id')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .catch((error) => {
        throw error;
      });
  }
}

