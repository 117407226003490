import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { GraphQLError } from 'graphql';

export default class ErrorHandler {
  static send(errors: Error|ReadonlyArray<Error>, tags: {[key: string] : string }, severityLevel: Severity = Severity.Error): void {
    const tenant = window.location.hostname.split('.').slice(0, -2).join('.');

    Sentry.captureException(errors, (scope) => {
      // scope.setUser({ id, ip_address }); // todo: add user info. Not available at the moment

      if (process.env.NODE_ENV === 'development') {
        switch (severityLevel) {
          case Severity.Error:
          case Severity.Critical:
          case Severity.Fatal:
            console.error(errors);
            break;
          case Severity.Debug:
            console.debug(errors);
            break;
          case Severity.Info:
            console.info(errors);
            break;
          case Severity.Log:
            console.log(errors);
            break;
          case Severity.Warning:
            console.warn(errors);
            break;
          default:
            console.log(severityLevel, errors);
            break;
        }
      }
      scope.setTags({ tenant, ...tags });
      scope.setLevel(severityLevel);
      return scope;
    });
  }

  static debug(message: string): void {
    Sentry.captureMessage(message, Severity.Debug);
  }

  static error(message: string): void {
    Sentry.captureMessage(message, Severity.Error);
  }

  static exception(error: Error): void {
    Sentry.captureException(error);
  }

  static fatal(message: string): void {
    Sentry.captureMessage(message, Severity.Fatal);
  }

  static graphQLError(errors: ReadonlyArray<GraphQLError>): void {
    const tags = {
      package: 'graphQL',
      error_type: 'Error',
    };
    ErrorHandler.send(errors, tags, Severity.Error);
  }

  static graphQLNetworkError(error: Error): void {
    const tags = {
      package: 'graphQL',
      error_type: 'Network error',
    };
    ErrorHandler.send(error, tags, Severity.Error);
  }

  static info(message: string): void {
    Sentry.captureMessage(message, Severity.Info);
  }

  static log(message: string): void {
    Sentry.captureMessage(message, Severity.Log);
  }

  static warning(message: string): void {
    Sentry.captureMessage(message, Severity.Warning);
  }
}
