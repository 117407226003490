
import {
  Component,
  Vue,
  Watch,
  Prop,
} from 'vue-property-decorator';
import ErrorHandler from '@/utils/ErrorHandler';
import useTourStore from '@/stores/tour';
import { IncomingDTO, ResponseToTour } from '@/helpers/mappers/tour-mapper';
import { FetchPolicy } from 'apollo-client';
import { inRange } from 'lodash';
import { Sections } from '@/types/SectionTypes';
import useDocumentTitleStore from '@/stores/documentTitle';

// Lazy loading imports
const HeaderBlock = () => import(
  /* webpackChunkName: "header-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/HeaderBlock.vue'
);
const ListBlock = () => import(
  /* webpackChunkName: "list-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ListBlock.vue'
);
const FormBlock = () => import(
  /* webpackChunkName: "form-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/FormBlock.vue'
);
const ChartBlock = () => import(
  /* webpackChunkName: "chart-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ChartBlock.vue'
);
const OpenTasksBlock = () => import(
  /* webpackChunkName: "open-tasks-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/OpenTasksBlock.vue'
);
const ArchivedTasksBlock = () => import(
  /* webpackChunkName: "archived-dossier-tasks-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/ArchivedTasksBlock.vue'
);
const DetailTaskBlock = () => import(
  /* webpackChunkName: "detail-dossier-task-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/DetailTaskBlock.vue'
);
const DossierNotesBlock = () => import(
  /* webpackChunkName: "dossier-notes-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/noteBlocks/DossierNotesBlock.vue'
);
const DetailNoteBlock = () => import(
  /* webpackChunkName: "detail-dossier-task-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/noteBlocks/DetailNoteBlock.vue'
);
const DossierFilesBlock = () => import(
  /* webpackChunkName: "dossier-files-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/fileBlocks/DossierFilesBlock.vue'
);
const DossierAuditsBlock = () => import(
  /* webpackChunkName: "dossier-audits-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/auditBlocks/DossierAuditsBlock.vue'
);
const DossierAuditBlock = () => import(
  /* webpackChunkName: "dossier-audit-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/auditBlocks/DossierAuditBlock.vue'
);

interface DetailInformation {
  id: number | string,
  name: 'header' | 'form' | 'list' | 'PanelContact' | 'PanelTasks' | 'PanelNotes' | 'task_module' | 'graph' | 'audit_module_list' | 'audit_module_detail',
  location: 'left' | 'top' | 'right' | 'hidden',
  label: string,
}

interface Blocks {
  blocks: Array<DetailInformation>,
}

interface Data {
  dossierDetailInformation: Blocks;
  tour: IncomingDTO|null
}

interface Response {
  data: Data,
}

type Type =
  'HeaderBlock'
  | 'FormBlock'
  | 'ListBlock'
  | 'OpenTasksBlock'
  | 'ArchivedTasksBlock'
  | 'DetailTaskBlock'
  | 'DossierNotesBlock'
  | 'DetailNoteBlock'
  | 'DossierFilesBlock'
  | 'DossierAuditsBlock'
  | 'ChartBlock'
  | 'DossierAuditBlock';

interface ComponentInformation {
  dossierId: number,
  blockId: number,
  context?: string,
  type: Type,
  name: string,
  title: string,
  rendered: boolean,
  autofocus?: boolean,
}

type ComponentsColumn = Array<ComponentInformation>;

type ComponentsTop = Array<ComponentInformation>;

@Component({
  name: 'DossierDetail',
  components: {
    HeaderBlock,
    ListBlock,
    FormBlock,
    OpenTasksBlock,
    ArchivedTasksBlock,
    DetailTaskBlock,
    DossierNotesBlock,
    DetailNoteBlock,
    DossierFilesBlock,
    DossierAuditsBlock,
    ChartBlock,
    DossierAuditBlock,
  },
})
export default class DossierDetail extends Vue {
  @Prop()
  private readonly dossierId?: number;

  @Prop()
  private readonly dossierTypeSlug?: string;

  @Prop()
  private readonly navigationSlug?: string;

  private loading = false;

  private tourStore = useTourStore();

  private documentTitleStore = useDocumentTitleStore();

  topRow: ComponentsTop = [];

  leftColumn: ComponentsColumn = [];

  leftColumnWidth = 0;

  rightColumn: ComponentsColumn = [];

  rightColumnWidth = 0;

  open = false;

  currentDialog = '';

  match = (name: string): Type => {
    switch (name) {
      case 'list':
        return 'ListBlock';
      case 'header':
        return 'HeaderBlock';
      case 'form':
        return 'FormBlock';
      case 'task_module':
        return 'OpenTasksBlock';
      case 'task_module_detail':
        return 'DetailTaskBlock';
      case 'task_module_finished':
        return 'ArchivedTasksBlock';
      case 'note_module_detail':
        return 'DetailNoteBlock';
      case 'note_module':
        return 'DossierNotesBlock';
      case 'file_module':
        return 'DossierFilesBlock';
      case 'audit_module_list':
        return 'DossierAuditsBlock';
      case 'graph':
        return 'ChartBlock';
      case 'audit_module_detail':
        return 'DossierAuditBlock';
      default:
        throw Error(`No component available for ${name}`);
    }
  };

  mounted(): void {
    this.fetchData();
  }

  created(): void {
    this.checkDialog();
  }

  private getDossierId(): number {
    return this.dossierId ?? parseInt(this.$route.params.dossierId, 10);
  }

  private getDossierTypeSlug(): string {
    return this.dossierTypeSlug ?? this.$route.params.dossierTypeSlug;
  }

  private getNavigationSlug(): string {
    return this.navigationSlug ?? this.$route.params.navigationSlug;
  }

  private async fetchData(fetchPolicy: FetchPolicy = 'network-only'): Promise<void> {
    const variables = {
      dossierTypeSlug: this.getDossierTypeSlug(),
      navigationSlug: this.getNavigationSlug(),
      dossierId: this.getDossierId(),
    };

    return this.$api
      .queryExecute('dossier-type-detail-information-by-dossier-type-slug-and-dossier-id', {
        variables,
        fetchPolicy,
      })
      .then((response: Response) => {
        this.handleData(response.data.dossierDetailInformation.blocks);
        if (response.data.tour !== null) {
          this.tourStore.$patch(ResponseToTour(response.data.tour));
        } else {
          this.tourStore.$reset();
        }
        this.tourStore.fullPath = this.$route.fullPath;
      })
      .finally(() => {
        this.loading = false;
        this.startTour();
      });
  }

  @Watch('rightColumn', { deep: true })
  @Watch('leftColumn', { deep: true })
  @Watch('topRow', { deep: true })
  private startTour(): void {
    const allComponentsArray = [...this.rightColumn, ...this.leftColumn, ...this.topRow];
    const amountOfRenderedComponents = allComponentsArray.filter((object) => object.rendered).length;
    if (allComponentsArray.length === amountOfRenderedComponents) {
      this.tourStore.start();
    }
  }

  private isAutofocusBlock(componentInformation: ComponentInformation, location: DetailInformation['location']): boolean {
    return !(componentInformation.type !== 'FormBlock'
      || location === 'right'
      || inRange(this.topRow.findIndex((block: ComponentInformation) => block.autofocus), 0, 2)
      || inRange(this.leftColumn.findIndex((block: ComponentInformation) => block.autofocus), 0, 1));
  }

  private handleData(data: Array<DetailInformation>): void {
    data.forEach((component) => {
      try {
        const componentInformation: ComponentInformation = {
          dossierId: this.getDossierId(),
          blockId: parseInt(String(component.id), 10),
          type: this.match(component.name),
          name: `${this.match(component.name) + component.id + component.location}`,
          title: component.label,
          rendered: false,
        };

        if (this.isAutofocusBlock(componentInformation, component.location)) {
          componentInformation.autofocus = true;
        }

        switch (component.location) {
          case 'left':
            this.leftColumn.push(componentInformation);
            break;
          case 'right':
            this.rightColumn.push(componentInformation);
            break;
          case 'top':
            this.topRow.push(componentInformation);
            break;
          case 'hidden':
            break;
          default:
            ErrorHandler.error(`The given location "${component.location}" is unknown.`);
        }
      } catch (errorMessage) {
        ErrorHandler.error(errorMessage);
      }
    });

    if (this.leftColumn.length > 0 && this.rightColumn.length > 0) {
      this.leftColumnWidth = 7;
      this.rightColumnWidth = 5;
    } else if (this.leftColumn.length > 0 && this.rightColumn.length === 0) {
      this.leftColumnWidth = 12;
    } else {
      this.rightColumnWidth = 12;
    }
  }

  @Watch('$route')
  OnRouteChanged(): void {
    this.checkDialog();
  }

  @Watch('open')
  openChanged(to: boolean): void {
    if (!to && this.$route.params.viewSlugId) {
      this.$router.push({ name: 'dossier-detail' });
    }
  }

  private checkDialog() {
    if (this.$route.params.viewSlugId) {
      this.open = true;
      this.currentDialog = this.$route.params.viewSlugId;
    } else {
      this.open = false;
    }
  }
}
