import Vue from 'vue';
import VueRouter, { RouteConfig, Location, Route } from 'vue-router';
import UserSettings from '@/components/commonComponents/UserSettings.vue';

/*
 * LAZY LOADING COMPONENTS
 *
 * It's a good practice to lazy load components that are not used on every page. This way of lazy
 * loading is called 'code splitting' in Vue. When building the app with the command 'yarn run build'
 * their will be compiled some chunk files in the 'source/dist/js' folder. By splitting the code the
 * chunks become smaller and the page will load faster because only the chunks needed are
 * downloaded to the client.
 *
 * For more info: https://vuejsdevelopers.com/2017/07/08/vue-js-3-ways-code-splitting-webpack/
 *
 * Example for how to use the code splitting
 * const Entry = () => import('@/components/entry/Entry.vue');
 *
 * If you want to give the chunk a more specific name you can add a comment in the import with the
 * following text 'webpackChunkName: "login"'
 */
const Entry = () => import(
  /* webpackChunkName: "entry" */
  /* webpackPrefetch: false */
  '@/components/entry/Entry.vue'
);
const Login = () => import(
  /* webpackChunkName: "login" */
  /* webpackPrefetch: false */
  '@/components/entry/login/Login.vue'
);
const Sidebar = () => import(
  /* webpackChunkName: "sidebar" */
  /* webpackPrefetch: false */
  '@/layouts/sidebar/Sidebar.vue'
);
const Dashboard = () => import(
  /* webpackChunkName: "dashboard" */
  /* webpackPrefetch: false */
  '@/components/dashboard/Dashboard.vue'
);
const ReportList = () => import(
  /* webpackChunkName: "report-list" */
  /* webpackPrefetch: false */
  '@/components/ReportList.vue'
);
const ActionList = () => import(
  /* webpackChunkName: "action-list" */
  /* webpackPrefetch: false */
  '@/components/ActionList.vue'
);
const ResetLogin = () => import(
  /* webpackChunkName: "reset-login" */
  /* webpackPrefetch: false */
  '@/components/entry/reset/Reset.vue'
);
const Registration = () => import(
  /* webpackChunkName: "registration" */
  /* webpackPrefetch: false */
  '@/components/entry/registration/Registration.vue'
);
const ReportDetail = () => import(
  /* webpackChunkName: "report-detail" */
  /* webpackPrefetch: false */
  '@/components/ReportDetail.vue'
);
const DossierDetail = () => import(
  /* webpackChunkName: "dossier-detail" */
  /* webpackPrefetch: false */
  '@/components/DossierDetail.vue'
);
const DossierTypeList = () => import(
  /* webpackChunkName: "dossier-type-list" */
  /* webpackPrefetch: false */ '@/components/DossierTypeList.vue'
);
const PageNotFound = () => import(
  /* webpackChunkName: "page-not-found" */
  /* webpackPrefetch: false */ '@/components/PageNotFound.vue'
);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Entry, Login and Registration
  {
    path: '/',
    component: Entry,
    children: [
      {
        path: '',
        name: 'index',
      },
      {
        path: 'reset-password/:token?',
        name: 'resetLogin',
        components: {
          ResetLogin,
        },
        meta: {
          requiresAuth: false,
          hideForAuth: true,
          layout: 'BlankLayout',
          title: 'Account reset',
        },
      },
      {
        path: 'logout',
        name: 'logout',
        redirect: 'login',
        meta: {
          title: 'Logout',
        },
      },
      {
        path: 'login',
        name: 'login',
        components: {
          Login,
        },
        meta: {
          requiresAuth: false,
          hideForAuth: true,
          layout: 'BlankLayout',
          title: 'Login',
        },
      },
      {
        path: 'registreren',
        name: 'register',
        components: {
          Registration,
        },
        meta: {
          requiresAuth: false,
          hideForAuth: true,
          layout: 'BlankLayout',
          title: 'Register account',
        },
      },
    ],
  },

  // Dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      showSidebar: false,
      title: 'Dashboard',
    },
  },

  // Settings
  {
    path: '/settings/update-password',
    name: 'Settings',
    component: UserSettings,
    meta: {
      showSidebar: false,
      title: 'Settings',
    },
  },

  // Dossier list
  {
    path: '/dossiers/:navigationGroup/:dossierTypeSlug/:navigationSlug',
    name: 'dossier-type-list',
    components: {
      default: DossierTypeList,
      Sidebar,
    },
    meta: { title: '{dossierTypeSlug} / {navigationSlug}' },
  },

  // Dossier detail redirect
  {
    path: '/dossiers/:navigationGroup/:dossierTypeSlug/:navigationSlug/detail/:dossierId',
    redirect: { name: 'dossier-detail' },
  },

  // Dossier detail
  {
    path: '/dossiers/:navigationGroup/:dossierTypeSlug/:navigationSlug/:dossierId',
    name: 'dossier-detail',
    components: {
      default: DossierDetail,
      Sidebar,
    },
    meta: { title: '{dossierTypeSlug} / {navigationSlug} / {dossierId}' },
  },

  // Report list
  {
    path: '/reports/:navigationGroup/:navigationSlug',
    name: 'report-list',
    components: {
      default: ReportList,
      Sidebar,
    },
    meta: { title: 'Reports / {navigationSlug}' },
  },

  // Report detail
  {
    path: '/reports/:navigationGroup/:navigationSlug/:groupSlug/:dossierTypeSlug',
    name: 'report-detail',
    components: {
      default: ReportDetail,
      Sidebar,
    },
    meta: { title: 'Reports / {navigationSlug} / {groupSlug} / {dossierTypeSlug}' },

  },

  // Task / contactmoment list
  {
    path: '/actions/:navigationGroup/:navigationSlug',
    name: 'action-list',
    components: {
      default: ActionList,
      Sidebar,
    },
    meta: { title: 'Actions / {navigationSlug}' },
  },

  // Page not found
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      requiresAuth: false,
      layout: 'BlankLayout',
      title: 'Page not found',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Give user message if they want to leave the page with unsaved changes
window.addEventListener('beforeunload', async (e) => {
  const screenHandlerStore = (await import('@/stores/screenHandler')).default();

  if (screenHandlerStore.isScreenDirty()) {
    e.preventDefault();
    e.returnValue = '';
  }
});

// Use documentTitleStore to add the document title to the page
router.beforeEach((to: Route, from, next) => {
  import('@/stores/documentTitle')
    .then((imported) => imported.default())
    .then((documentTitle) => {
      documentTitle.setCurrentRoute(to);
      documentTitle.setTitle();
    });
  next();
});

// Redirect old dialog urls to the new view url
router.beforeEach(async (to, from, next) => {
  const { hash } = to;

  const newHash = hash
    .replace(/(#)dialog-/g, '#view-')
    .replace(/(\|)dialog-/g, '|view-')
    .toLowerCase();

  if (newHash !== hash) {
    next(`${to.path}${newHash}`);
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const screenHandlerStore = (await import('@/stores/screenHandler')).default();

  if (await screenHandlerStore.leaveScreen()) {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const authStore = (await import('@/stores/auth')).default();

  if (to.redirectedFrom === '/logout' || to.path === '/logout') {
    authStore.logout();
  }

  let { meta } = to;

  if (typeof meta === 'undefined') {
    meta = {};
  }
  if (typeof meta.requiresAuth === 'undefined') {
    // by default this app requires auth, add `meta.requiresAuth = false` to make routes public
    meta.requiresAuth = true;
  }
  if (typeof meta.hideForAuth === 'undefined') {
    meta.hideForAuth = false;
  }
  if (typeof meta.layout === 'undefined') {
    meta.layout = 'FullLayout';
  }

  const isUserLoggedIn = await authStore.isAuthenticated();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      authStore.logout();
      const toLogin: Location = {
        name: 'login',
        query: { redirect: to.fullPath },
      };
      if (to.name === 'index') { // no need to keep empty redirect query when on index
        delete toLogin.query;
      }
      next(toLogin);
      next();
    } else if (to.name === 'index') { // redirect logged-in user on index to dashboard
      next({ name: 'dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }

  /**
   * Check if user still has a login session, if so redirect to Dashboard.
   * If the link navigates to the register page, logout the user that if someone is logged in
   */
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (isUserLoggedIn && to.name !== 'register') {
      next({ name: 'dashboard' });
    } else if (isUserLoggedIn && to.name === 'register') {
      authStore.logout();
    } else {
      next();
    }
  } else {
    next();
  }
});

// Decode html characters in url.
router.afterEach(async (to, from) => {
  const currentPath = to.fullPath;
  const decodedPath = decodeURI(currentPath);

  // Replaces all but the first # with a pipe (|)
  const correctedPath = decodedPath.replace(/#/g, (c, i, text) => (text.indexOf(c) === i ? c : '|'));

  if (correctedPath !== to.fullPath) {
    const routeLocation = router.resolve(correctedPath).location;
    router.resolve(routeLocation, from);
  }
});

export default router;
