
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import EventBus from '@/event-bus';
import ErrorHandler from '@/utils/ErrorHandler';
import { hashArrayToUrl, urlHashToArray } from '@/helpers/converters/url';
import Message from '@/components/mixins/Message.vue';
import DossierDetail from '@/components/DossierDetail.vue';
import useDocumentTitleStore from '@/stores/documentTitle';

// Lazy loading imports
const HeaderBlock = () => import(
  /* webpackChunkName: "header-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/HeaderBlock.vue'
);
const ListBlock = () => import(
  /* webpackChunkName: "list-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ListBlock.vue'
);
const FormBlock = () => import(
  /* webpackChunkName: "form-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/FormBlock.vue'
);
const ChartBlock = () => import(
  /* webpackChunkName: "chart-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ChartBlock.vue'
);
const OpenTasksBlock = () => import(
  /* webpackChunkName: "open-tasks-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/OpenTasksBlock.vue'
);
const ArchivedTasksBlock = () => import(
  /* webpackChunkName: "archived-dossier-tasks-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/ArchivedTasksBlock.vue'
);
const DetailTaskBlock = () => import(
  /* webpackChunkName: "detail-dossier-task-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/DetailTaskBlock.vue'
);
const DetailNoteBlock = () => import(
  /* webpackChunkName: "detail-dossier-notes-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/noteBlocks/DetailNoteBlock.vue'
);
const DossierNotesBlock = () => import(
  /* webpackChunkName: "detail-dossier-notes-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/noteBlocks/DossierNotesBlock.vue'
);
const DossierFilesBlock = () => import(
  /* webpackChunkName: "detail-dossier-files-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/fileBlocks/DossierFilesBlock.vue'
);
const DossierAuditsBlock = () => import(
  /* webpackChunkName: "detail-dossier-audit-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/auditBlocks/DossierAuditsBlock.vue'
);
const DossierAuditBlock = () => import(
  /* webpackChunkName: "dossier-audit-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/auditBlocks/DossierAuditBlock.vue'
);

interface DetailInformation {
  id: string,
  name: 'header' | 'form' | 'list' | 'PanelContact' | 'PanelTasks' | 'PanelNotes' | 'task_module' | 'graph' | 'audit_module_list' | 'audit_module_detail',
  location: 'left' | 'top' | 'right' | 'hidden',
  label: string,
}

interface Blocks {
  blocks: Array<DetailInformation>,
}

interface Data {
  dossierDetailInformation: Blocks;
}

interface Response {
  data: Data,
}

type Type =
  'HeaderBlock'
  | 'FormBlock'
  | 'ListBlock'
  | 'OpenTasksBlock'
  | 'ArchivedTasksBlock'
  | 'DetailTaskBlock'
  | 'DetailNoteBlock'
  | 'DossierNotesBlock'
  | 'DossierFilesBlock'
  | 'DossierAuditsBlock'
  | 'ChartBlock'
  | 'DossierAuditBlock';

interface ComponentInformation {
  dossierId: number,
  blockId: number,
  context: string,
  type: Type,
  name: string,
  title: string,
}

type ComponentsColumn = Array<ComponentInformation>;

type ComponentsTop = Array<ComponentInformation>;

@Component({
  name: 'Dialog',
  components: {
    DossierDetail,
    HeaderBlock,
    ListBlock,
    FormBlock,
    OpenTasksBlock,
    ArchivedTasksBlock,
    DetailTaskBlock,
    DetailNoteBlock,
    DossierNotesBlock,
    DossierFilesBlock,
    DossierAuditsBlock,
    ChartBlock,
    DossierAuditBlock,
  },
})
export default class Dialog extends Vue {
  @Prop({ required: true })
  private readonly graphqlOperation!: string;

  @Prop({ required: true })
  private readonly viewSlug!: string;

  @Prop()
  protected readonly navigationSlug?: string;

  @Prop()
  protected readonly dossierTypeSlug?: string;

  @Prop()
  protected readonly dossierId?: number;

  @Prop()
  private readonly value?: boolean;

  @Prop({ required: true })
  private readonly type!: string;

  @Prop({ required: true })
  private readonly index!: number;

  @Prop({ required: true })
  private readonly context!: string;

  protected topRow: ComponentsTop = [];

  protected leftColumn: ComponentsColumn = [];

  protected leftColumnWidth = 0;

  protected rightColumn: ComponentsColumn = [];

  protected rightColumnWidth = 0;

  private documentTitleStore = useDocumentTitleStore();

  match = (name: string): Type => {
    switch (name) {
      case 'list':
        return 'ListBlock';
      case 'header':
        return 'HeaderBlock';
      case 'form':
        return 'FormBlock';
      case 'task_module':
        return 'OpenTasksBlock';
      case 'task_module_detail':
        return 'DetailTaskBlock';
      case 'task_module_finished':
        return 'ArchivedTasksBlock';
      case 'note_module_detail':
        return 'DetailNoteBlock';
      case 'note_module':
        return 'DossierNotesBlock';
      case 'file_module':
        return 'DossierFilesBlock';
      case 'audit_module_list':
        return 'DossierAuditsBlock';
      case 'graph':
        return 'ChartBlock';
      case 'audit_module_detail':
        return 'DossierAuditBlock';
      default:
        throw Error(`No component available for ${name}`);
    }
  };

  mounted(): void {
    if (this.type !== 'detail') {
      this.fetchData()
        .then(() => {
          this.documentTitleStore.addHashInfo({
            [this.context]: {
              blocks: {
                topRow: this.topRow,
                leftColumn: this.leftColumn,
                rightColumn: this.rightColumn,
              },
            },
          });
        });
    }
  }

  private getDossierTypeSlug(): string {
    return this.dossierTypeSlug ?? this.$route.params.dossierTypeSlug;
  }

  private getNavigationSlug(): string {
    return this.navigationSlug ?? this.$route.params.navigationSlug;
  }

  getDossierId(): number {
    if (typeof this.dossierId === 'number') {
      return this.dossierId;
    }
    if (typeof this.dossierId === 'string') {
      return parseInt(this.dossierId, 10);
    }

    const routeHashString = this.$route.hash || window.location.hash;
    const dialogsArray = urlHashToArray(routeHashString);
    const currentPath = window.location.pathname.split('/');
    const { index } = this;

    // If the router hash contains the string 'dossier-' and there are dialogs available
    if (index !== undefined && routeHashString.includes('dossier-') && dialogsArray.length > 0 && dialogsArray[index]?.includes('dossier-')) {
      const id = dialogsArray[index].split('/').pop();
      if (id !== undefined) {
        return parseInt(id, 10);
      }
    }

    const foundDossiers = index !== undefined && dialogsArray.filter((element, key) => key < index && element.includes('dossier-'));
    if (foundDossiers && foundDossiers[foundDossiers.length - 1]) {
      const slugAndContext = foundDossiers[foundDossiers.length - 1].split('-');
      return parseInt(slugAndContext[1], 10);
    }

    // If the dossier type id isset in as a param, return it straightaway
    if (this.$route.params.dossierId) {
      return parseInt(this.$route.params.dossierId, 10);
    }

    const idOrNan = parseInt(currentPath[currentPath.length - 1], 10);
    if (Number.isInteger(idOrNan)) {
      return idOrNan;
    }

    if (index !== undefined && routeHashString.includes('detail-') && dialogsArray.length > 0) {
      const slugAndDossierId = dialogsArray[index].split('-');
      const id = slugAndDossierId[0] !== 'dialog' && parseInt(slugAndDossierId[1], 10);

      if (id && Number.isInteger(id)) {
        return id;
      }

      const found = dialogsArray.find((element) => element.includes('detail-'));
      if (found) {
        const slugAndContext = found.split('-');
        return parseInt(slugAndContext[1], 10);
      }
    }

    throw Error('No dossier type id found');
  }

  protected async closeDialog(): Promise<void> {
    let currentHash = this.$route.hash;
    if (currentHash.length === 0) {
      const routeHash = window.location.hash;
      currentHash = routeHash.replace('#', '');
    }

    const currentHashArray = urlHashToArray(currentHash);
    const newHashArray = currentHashArray.slice(0, -1);
    const newHash = hashArrayToUrl(newHashArray);

    if (newHash !== currentHash) {
      await this.$router.push({ hash: newHash });
      this.$destroy();
    }
  }

  @Watch('$route.hash')
  private setFocusToMostUpperDialog(): void {
    const currentHash = this.$route.hash;
    const currentHashArray = urlHashToArray(currentHash);

    if (this.index + 1 === currentHashArray.length) {
      setTimeout(() => ((this.$refs.dialog as Vue).$children[0].$el.children[0] as HTMLElement).focus(), 0);
    }
  }

  private async fetchData(): Promise<void> {
    return import(`@/graphql/${this.graphqlOperation}`)
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables: {
          viewSlug: `${this.viewSlug}`,
          dossierTypeSlug: this.getDossierTypeSlug(),
          navigationSlug: this.getNavigationSlug(),
          dossierId: this.getDossierId(),
          context: this.context,
        },
      }))
      .then((response: Response) => {
        this.handleData(response.data.dossierDetailInformation.blocks);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      });
  }

  private handleData(data: Array<DetailInformation>): void {
    data.forEach((component) => {
      try {
        const componentInformation: ComponentInformation = {
          dossierId: this.getDossierId(),
          blockId: parseInt(component.id, 10),
          context: this.context,
          type: this.match(component.name),
          name: this.match(component.name) + component.id + component.location,
          title: component.label,
        };

        switch (component.location) {
          case 'left':
            this.leftColumn.push(componentInformation);
            break;
          case 'right':
            this.rightColumn.push(componentInformation);
            break;
          case 'top':
            this.topRow.push(componentInformation);
            break;
          case 'hidden':
            break;
          default:
            ErrorHandler.error(`The given location "${component.location}" is unknown.`);
        }
      } catch (errorMessage) {
        ErrorHandler.error(errorMessage);
      }
    });

    if (this.leftColumn.length > 0 && this.rightColumn.length > 0) {
      this.leftColumnWidth = 7;
      this.rightColumnWidth = 5;
    } else if (this.leftColumn.length > 0 && this.rightColumn.length === 0) {
      this.leftColumnWidth = 12;
    } else {
      this.rightColumnWidth = 12;
    }
  }

  private deletedEvent(): void {
    this.closeDialog();
    EventBus.$emit('initializeForceRerender');
  }
}
