import { ButtonType, ClickAction, ClickActions } from '@/types/ButtonTypes';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SweetAlertOptions } from 'sweetalert2';
import { Vue } from 'vue-property-decorator';

export default class ButtonHandler {
  private readonly type: ButtonType;

  private readonly confirmMessage: string|undefined;

  private readonly clickActions: ClickActions;

  public constructor(
    type: ButtonType,
    confirmMessage: string|undefined,
    clickActions: ClickActions|ClickAction,
  ) {
    this.type = type;
    this.confirmMessage = confirmMessage;
    this.clickActions = Array.isArray(clickActions) ? clickActions : [clickActions];
    return this;
  }

  public needsConfirm(): boolean {
    return this.confirmMessage !== undefined;
  }

  public executeActions(): void {
    for (let i = 0; i < this.clickActions.length; i += 1) {
      if (this.clickActions[i]?.() === false) {
        break;
      }
    }
  }

  public getSweetAlertOptions(): SweetAlertOptions {
    const defaultOptions = {
      confirmButtonColor: 'var(--v-primary_button_color-base)',
      showCancelButton: true,
      cancelButtonText: Vue.prototype.$t('generic.confirm.cancel'),
      cancelButtonColor: 'var(--v-primary_button_color-base)',
      customClass: {
        confirmButton: 'action-button',
        cancelButton: 'action-button',
        denyButton: 'action-button',
      },
    };

    switch (this.type) {
      case 'delete': return {
        ...defaultOptions,
        ...{
          title: Vue.prototype.$t('delete.confirm.confirm'),
          text: this.confirmMessage ?? Vue.prototype.$t('delete.confirm.question'),
          icon: 'warning',
          confirmButtonText: Vue.prototype.$t('delete.confirm.confirm'),
        },
      };
      case 'submit': return {
        ...defaultOptions,
        ...{
          title: Vue.prototype.$t('generic.confirm.confirm'),
          text: this.confirmMessage ?? Vue.prototype.$t('generic.confirm.question'),
          icon: 'question',
          confirmButtonText: Vue.prototype.$t('generic.confirm.confirm'),
        },
      };
      default: return {
        ...defaultOptions,
        ...{
          title: this.confirmMessage ?? Vue.prototype.$t('generic.confirm.question'),
          icon: 'question',
          confirmButtonText: Vue.prototype.$t('generic.confirm.confirm'),
        },
      };
    }
  }
}
