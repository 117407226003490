
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import useTenantSettingsStore from '@/stores/tenantSettings';

@Component({
  name: 'Message',
})
export default class Message extends Vue {
  public static custom(message: string, type: 'error' | 'success'): void {
    useTenantSettingsStore().showSnackbar(message, type);
  }

  public static error(message: string): void {
    Message.custom(message, 'error');
  }

  public static success(message: string): void {
    Message.custom(message, 'success');
  }
}
