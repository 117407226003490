export default class UriMapRegexEntry {
  public regex: RegExp;

  public replacement: string;

  constructor(regex: RegExp, replacement: string) {
    this.replacement = replacement;
    this.regex = regex;
  }

  getUri(uri: string): string {
    return uri.replace(this.regex, this.replacement);
  }
}
