import { from } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApiUriResolver } from '@/plugins/apollo/api-uri-resolver';
import ErrorHandler from '@/utils/ErrorHandler';
import useAuthStore from '@/stores/auth';
import { ServerError } from 'apollo-link-http-common';

const UriResolver = new ApiUriResolver();

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  let unauthenticated;
  if (Object.keys(networkError ?? {}).includes('statusCode') && (networkError as ServerError).statusCode === 404) {
    window.location.href = 'https://www.unity-x.nl/'; // todo: Read response Location header somehow
    return;
  }
  if (Object.keys(networkError ?? {}).includes('statusCode') && (networkError as ServerError).statusCode === 401) {
    unauthenticated = true;
  } else if (networkError?.message === 'No auth token returned') {
    unauthenticated = true;
  } else if (response?.errors?.some((error) => error.message.includes('Unauthenticated')) === true) {
    unauthenticated = true;
  } else {
    unauthenticated = <boolean>(
      graphQLErrors !== undefined
      && graphQLErrors.find((error) => error.message)?.message.includes('Unauthenticated')
    );
  }

  if (unauthenticated && !(window.location.hash.includes('login') || window.location.pathname.includes('login'))) {
    useAuthStore().logout();
    window.location.reload();
  }

  if (graphQLErrors) {
    ErrorHandler.graphQLError(graphQLErrors);
  }
  if (networkError) {
    ErrorHandler.graphQLNetworkError(networkError);
  }
});

const authLink = setContext(async (_, { headers }) => {
  await delay(5); // Otherwise race condition with the refreshToken request
  const authStore = useAuthStore();
  const token = await authStore.getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Full-Path': window.location.pathname + window.location.hash,
    },
  };
});

const httpLink = createUploadLink({
  uri: (operation) => `${UriResolver.resolveUri('graphql')}?operationName=${operation.operationName}`,
  credentials: 'same-origin',
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'DossierListHeaderMetadata',
          possibleTypes: [
            {
              name: 'TagMetadata',
            },
            {
              name: 'GroupMetadata',
            },
            {
              name: 'AssignmentMetadata',
            },
          ],
        },
      ],
    },
  },
});

const cache = new InMemoryCache({ fragmentMatcher });

export default new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
});
