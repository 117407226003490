import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import { GraphQLVariables } from '@/plugins/api/types/graph-ql';
import Query from './graph-ql-query';

interface queryOptions {
  uid?: string,
  variables?: GraphQLVariables,
  fetchPolicy?: FetchPolicy,
}

export default class ApiResolver {
  private static instance: ApiResolver;

  public static getInstance(): ApiResolver {
    if (!ApiResolver.instance) {
      ApiResolver.instance = new ApiResolver();
    }

    return ApiResolver.instance;
  }

  public queryExecute(graphQLEndpoint: string, options?: queryOptions): Promise<ApolloQueryResult<unknown>> {
    return this.query(graphQLEndpoint, options).execute();
  }

  // eslint-disable-next-line class-methods-use-this
  public query(graphQLEndpoint: string, options?: queryOptions): Query {
    const query = new Query(graphQLEndpoint);

    if (options !== undefined && options.uid) {
      query.setUID(options.uid);
    }
    if (options !== undefined && options.variables) {
      query.setVariables(options.variables);
    }
    if (options !== undefined && options.fetchPolicy) {
      query.setFetchPolicy(options.fetchPolicy);
    }

    return query;
  }
}
