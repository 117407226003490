
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'BaseCard',
})
export default class BaseCard extends Vue {
  @Prop({ required: true })
  private readonly title: string | undefined;

  @Prop()
  private readonly actions?: Array<{ [key: string]: string }>;

  @Prop({ default: false })
  private readonly loading!: boolean;

  @Prop({ default: false })
  private readonly hasExport?: boolean;

  @Prop()
  private readonly exportFile?: (() => void)
}
