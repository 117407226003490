import {
  GraphQLEndpoint,
  GraphQLVariables,
} from '@/plugins/api/types/graph-ql';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export default abstract class GraphQlRequest {
  protected abstract requestType: 'query'|'mutation';

  protected uid = '';

  protected request = '';

  private graphQLString = '';

  protected loading = false;

  private graphQLEndpoint: string|undefined;

  private graphQLVariables: GraphQLVariables = {};

  constructor(graphQLEndpoint?: GraphQLEndpoint) {
    this.graphQLEndpoint = graphQLEndpoint;
    return this;
  }

  public setEndpoint(graphQLEndpoint: GraphQLEndpoint): this {
    this.graphQLEndpoint = graphQLEndpoint;
    return this;
  }

  public setVariables(graphQLVariables: GraphQLVariables): this {
    this.graphQLVariables = graphQLVariables;
    return this;
  }

  public setUID(uid: string): this {
    this.uid = uid;
    return this;
  }

  public setRequestString(graphQLString: string): this {
    this.graphQLString = graphQLString;
    return this;
  }

  public async getRequestString(): Promise<string> {
    let string = this.graphQLString;

    if (string === '') {
      string = await this.import();
    }

    return new Promise((resolve) => {
      resolve(this.makeUnique(string));
    });
  }

  public getVariables(): GraphQLVariables {
    return this.variables;
  }

  public abstract execute(fetchPolicy: FetchPolicy): Promise<ApolloQueryResult<unknown>>

  public async getRequest(): Promise<{ request: DocumentNode, variables: GraphQLVariables }> {
    const request = (await this.getGraphQLTag());
    return {
      request,
      variables: this.variables,
    };
  }

  protected get endpoint(): string {
    if (!this.graphQLEndpoint) {
      throw Error('No endpoint set');
    }

    return this.graphQLEndpoint;
  }

  /**
   * Get variables were all the keys of have the uid in front of it so that the variables will
   * always be unique
   * @protected
   */
  protected get variables(): GraphQLVariables {
    const variables: GraphQLVariables = {};

    Object.keys(this.graphQLVariables).forEach((variableName: string) => {
      variables[`${this.uid}${variableName}`] = this.graphQLVariables[variableName];
    });

    return variables;
  }

  // eslint-disable-next-line class-methods-use-this
  protected correctResponse(result: ApolloQueryResult<unknown>): ApolloQueryResult<unknown>|string {
    return JSON.parse(
      JSON.stringify(result)
        .replaceAll(this.uid, ''),
    );
  }

  protected async getGraphQLTag(): Promise<DocumentNode> {
    const string = await this.getRequestString();

    return new Promise((resolve) => {
      resolve(gql(string));
    });
  }

  /**
   * Get the graphql document container the graphql endpoint.
   * First check in the @/graphql/queries folder. If nothing is found, start the same process for
   * the @/graphql/operation folder
   * @private
   */
  private import(): Promise<string> {
    const { endpoint } = this;
    const specificFolder = this.requestType === 'query' ? 'queries' : 'mutations';

    return new Promise((resolve) => {
      // First folder check
      import(`@/graphql/${specificFolder}/${endpoint}`)
        .then(({ default: query }) => {
          this.graphQLString = query;
          resolve(query.loc.source.body);
        })

        // If not first folder, check second folder
        .catch((): void => {
          import(`@/graphql/operations/${endpoint}`)
            .then(({ QUERY: query }) => {
              this.graphQLString = query;
              resolve(query.loc.source.body);
            });
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private makeUnique(graphQLString: string): string {
    return graphQLString
      .replaceAll('$', `$${this.uid}`)
      .replaceAll(/(?<=\s|\n)((?<!\$)([a-zA-Z-_]+:)(?!(\s?\$)))/gm, (value: string) => `${this.uid}${value}`);
  }
}
