import Vue from 'vue';

const EventBus = new Vue();

EventBus.$on('initializeForceRerender', async () => {
  const screenHandlerStore = (await import('@/stores/screenHandler')).default();

  if (await screenHandlerStore.leaveScreen()) {
    EventBus.$emit('forceRerender');
  }
});

export default EventBus;
