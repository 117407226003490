export default class Waiter {
  private resolve: undefined|((value?: void|PromiseLike<void>) => void);

  private reject: undefined|((reason?: unknown) => void);

  private promise: Promise<void> = Promise.resolve();

  get wait(): Promise<void> {
    return this.promise;
  }

  public start(): this {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    return this;
  }

  public stop(): this {
    if (this.resolve !== undefined) {
      this.resolve();
    }
    return this;
  }
}
