import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import nl from 'vuetify/src/locale/nl';

Vue.use(Vuetify);

const defaultLightTheme = {
  success: '#4CAF50',
  error: '#FF5252',
  info: '#2196F3',
  warning: '#FFC107',
  accent: '#82B1FF',
  primary_color: '#1E88E5',
  primary_background_color: '#F4F6F9',
  primary_panel_background_color: '#FFFFFF',
  secondary_panel_background_color: '#FAFAFA',
  primary_text_color: '#414141',
  secondary_text_color: '#A1AAB2',
  primary_link_color: '#1E88E5',
  primary_list_link_color: '#1E88E5',
  primary_button_color: '#1E88E5',
  primary_button_text_color: '#FFFFFF',
};

const defaultDarkTheme = {
  success: '#4CAF50',
  error: '#FF5252',
  info: '#2196F3',
  warning: '#FFC107',
  accent: '#82B1FF',
  primary_color: '#1E88E5',
  primary_background_color: '#111519',
  primary_panel_background_color: '#1E1E1E',
  secondary_panel_background_color: '#191919',
  primary_text_color: '#FFFFFF',
  secondary_text_color: '#A1AAB2',
  primary_link_color: '#1E88E5',
  primary_list_link_color: '#1E88E5',
  primary_button_color: '#1E88E5',
  primary_button_text_color: '#FFFFFF',
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: defaultLightTheme,
      dark: defaultDarkTheme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
  lang: {
    locales: { nl },
    current: 'nl',
  },
});
