

import { Vue, Component } from 'vue-property-decorator';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import Message from '@/components/mixins/Message.vue';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import { FetchPolicy } from 'apollo-client';
import { Model, Schema, Options } from '@/types/vjsf';

@Component({
  name: 'UserSettings',
  components: {
    BaseCard,
    VJsfForm,
  },
  data: () => ({
    model: { } as Model,
    initialModel: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null,
    metadata: undefined,
    title: '',
  }),
})
export default class UserSettings extends Vue {
  private loading = false;

  protected mounted(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    import('@/graphql/operations/update-password')
      .then(({ QUERY: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response) => {
        this.$data.model = response.data.form.model;
        this.$data.initialModel = { ...response.data.form.model };
        this.$data.valid = response.data.form.valid;
        this.$data.options = response.data.form.options;
        this.$data.schema = response.data.form.schema;
        this.$data.metadata = response.data.form.metadata;
        this.$data.title = response.data.form.title;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private updatePassword(): void {
    this.loading = true;

    const variables = {
      input: this.$data.model,
    };

    import('@/graphql/operations/update-password')
      .then(({ MUTATION: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        Message.success(response.data.form.message);
        this.$data.model = {};
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
