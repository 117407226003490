import { ApiUriResolver } from '@/plugins/apollo/api-uri-resolver';
import Waiter from '@/utils/Waiter';
import { isEqual } from 'lodash';

export default class AuthTokenHelper {
  private keys = { authToken: 'auth-token', refreshToken: 'auth-refresh-token', expiresOn: 'auth-expires-on-timestamp' };

  private waiter: Waiter = new Waiter();

  private storage: Storage;

  constructor(storage = localStorage) {
    this.storage = storage;
  }

  public async getAuthToken(): Promise<string|null> {
    await this.waiter.wait;

    if (this.isTokenExpired() && this.hasRefreshToken()) {
      await this.requestNewTokens();
    }

    return this.storage.getItem(this.keys.authToken);
  }

  public getRefreshToken(): string|null {
    return this.storage.getItem(this.keys?.refreshToken);
  }

  public getExpiresOnTimestamp(): number|null {
    return AuthTokenHelper.toNumberOrNull(
      this.storage.getItem(this.keys.expiresOn),
    );
  }

  public async isAuthenticated(): Promise<boolean> {
    const token = await this.getAuthToken();
    return token !== null && token !== '';
  }

  public logout(): void {
    Object.values(this.keys).forEach((key: string) => {
      this.storage.removeItem(key);
    });
  }

  public isTokenExpired(): boolean {
    const expiresOn = this.getExpiresOnTimestamp();
    return expiresOn !== null && expiresOn < AuthTokenHelper.getNowTimestamp();
  }

  public hasRefreshToken(): boolean {
    const refreshToken = this.getRefreshToken();
    return refreshToken !== null && refreshToken !== '';
  }

  public async setTokens(authToken: string, refreshToken: string|null, expiresIn: number|null): Promise<void> {
    return new Promise((resolve) => {
      this.storage.setItem(this.keys.authToken, authToken);

      if (refreshToken !== null) {
        this.storage.setItem(this.keys?.refreshToken, refreshToken);
      }

      if (expiresIn !== null) {
      /*
        slackInSeconds
        Expire the internal token an x amount of seconds before the actual expire time. This
        change was added after complaints that users got logged out of the system while working.
        The change is made to experiment, and see if this fixes the mentioned problem.
        If this is the case, the change should be improved to a more solid one.
       */
        const slackInSeconds = -30;
        const expiresOn = AuthTokenHelper.getNowTimestamp() + expiresIn + slackInSeconds;
        this.storage.setItem(this.keys.expiresOn, String(expiresOn));
      }
      resolve();
    });
  }

  private async requestNewTokens(): Promise<void> {
    this.waiter.start();
    const variables = {
      input: {
        refresh_token: this.storage.getItem(this.keys.refreshToken),
      },
    };
    const url = (new ApiUriResolver()).resolveUri();

    return window.fetch(`${url}graphql?refreshToken`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        query: `mutation refreshToken ($input: RefreshTokenInput) {
          refreshToken(input: $input ) {
            token
            refresh_token
            expires_in
          }
        }`,
        variables,
      }),
    }).then((response) => response.json())
      .then((tokenResponse) => {
        if (tokenResponse?.data?.refreshToken && isEqual(Object.keys(tokenResponse?.data?.refreshToken), ['token', 'refresh_token', 'expires_in'])) {
          const {
            token: authToken,
            refresh_token: newRefreshToken,
            expires_in: expiresIn,
          } = tokenResponse.data.refreshToken;
          this.setTokens(authToken, newRefreshToken, expiresIn);
        } else {
          this.logout();
          window.location.reload();
          throw new Error('No auth token returned');
        }
      })
      .finally(() => {
        this.waiter.stop();
      })
      .catch((error) => {
        this.logout();
        throw error;
      });
  }

  private static getNowTimestamp(): number {
    return Math.floor(Date.now() / 1000);
  }

  private static toNumberOrNull(value: string|null): number|null {
    return value !== null ? parseInt(value, 10) : null;
  }
}
