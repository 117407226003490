import { defineStore } from 'pinia';
import AuthTokenHelper from '@/plugins/auth/auth-token-helper';

const useAuthStore = defineStore('auth', {
  state: () => ({
    authTokenHelper: new AuthTokenHelper(localStorage),
  }),
  actions: {
    async isAuthenticated(): Promise<boolean> {
      return this.authTokenHelper.isAuthenticated();
    },
    storeTokens(authToken: string, refreshToken: string|null = null, expiresIn: number|null = null): void {
      this.authTokenHelper.setTokens(authToken, refreshToken, expiresIn);
    },
    logout(): void {
      this.authTokenHelper.logout();
    },
    async getToken(): Promise<string|null> {
      return this.authTokenHelper.getAuthToken();
    },
  },
});

export default useAuthStore;
