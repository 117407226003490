import { createPinia, PiniaVuePlugin } from 'pinia';
import Vue from 'vue';
import { VueMaskDirective, VueMaskFilter } from 'v-mask';
import ErrorHandler from '@/utils/ErrorHandler';
import VueTour from 'vue-tour';
import GenericTranslator from '@/plugins/translations/src/index';
import UniqueId from 'vue-unique-id';
import apolloProvider from '@/plugins/apollo';
import SweetAlert2 from 'vue-sweetalert2/dist/index';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import sentryInit from './plugins/sentry';
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css';
import API from './plugins/api/src/index';

// Lazy loading
const VueApexCharts = () => import(
  /* webpackChunkName: "vue-apex-charts" */
  'vue-apexcharts'
);

Vue.use(API);

Vue.use(SweetAlert2);

Vue.use(UniqueId);
require('vue-tour/dist/vue-tour.css');

Vue.use(GenericTranslator);

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(VueTour);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
Vue.config.productionTip = false;

Vue.directive('mask', VueMaskDirective);
Vue.filter('mask', VueMaskFilter);

// Enable better performance monitoring for Vue apps
Vue.config.performance = true;

// Error handlers
Vue.config.errorHandler = (error) => {
  ErrorHandler.exception(error);
};
window.onerror = (message, source, lineno, colno, error: Error | undefined) => {
  if (error instanceof Error) {
    ErrorHandler.exception(error);
  } else if (typeof message === 'string') {
    ErrorHandler.error(message);
  } else {
    ErrorHandler.log(`An error occurred with no message of error on:
      \tsource: ${source ?? 'undefined'},
      \tline:   ${lineno ?? 'undefined'},
      \tcolumn: ${colno ?? 'undefined'}
    `);
  }
};

sentryInit();

const app = new Vue({
  pinia,
  router,
  apolloProvider,
  vuetify,
  render: (h) => h(App),
});

app.$mount('#app');
