export default class UriMapEntry {
  public key: string;

  public value: string;

  constructor(key: string, value: string) {
    this.value = value;
    this.key = key;
  }
}
