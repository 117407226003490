import ApolloClient from '@/plugins/apollo/apollo-client';
import GraphQlRequest from '@/plugins/api/src/graph-ql-request';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';

export default class Query extends GraphQlRequest {
  protected requestType: 'query'|'mutation' = 'query';

  private fetchPolicy: FetchPolicy = 'cache-first';

  public setFetchPolicy(fetchPolicy: FetchPolicy): void {
    this.fetchPolicy = fetchPolicy;
  }

  public async execute(fetchPolicy: FetchPolicy = 'cache-first'): Promise<ApolloQueryResult<unknown>> {
    this.loading = true;

    return new Promise((resolve) => this.getGraphQLTag()
      .then((query) => ApolloClient.query({
        query,
        variables: this.variables,
        fetchPolicy,
      }))
      .then((response) => {
        const returnObject = response;
        returnObject.data = this.correctResponse(response.data);
        resolve(returnObject);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.loading = false;
      }));
  }
}
