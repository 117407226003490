import { defineStore } from 'pinia';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultLogo = require('@/assets/images/logo-flux-flow-wit.png');

interface State {
  authMethods: Array<string>,
  datetimeLocale: string,
  snackbar: {
    active: boolean,
    color: string,
    message: string,
    timeout: number,
  }
  'logoMain': string|null,
  'logoLogin': string|null,
  vuetifySettings: any,
}

const useTenantSettingsStore = defineStore('tenantSettings', {
  state: (): State => ({
    authMethods: [],
    datetimeLocale: '',
    snackbar: {
      active: false,
      color: '',
      message: '',
      timeout: 2500,
    },
    logoMain: defaultLogo,
    logoLogin: defaultLogo,
    vuetifySettings: {},
  }),
  actions: {
    showSnackbar(message: string, color: string) {
      this.$patch({
        snackbar: {
          active: true,
          color,
          message,
        },
      });
    },
  },
});

export default useTenantSettingsStore;
